<template>
	<div>
		<div class="header">
		  <van-nav-bar
		    :title="$t('assets.kuanji')"
		    left-arrow
		    @click-left="$router.go(-1)"
		  />
		</div>
		<div class="mining-nav"></div>
		<!-- <div class="mining-tg">
			<div class="mining-tg-title">托管金额</div>
			<div class="mining-tg-amount">10000</div>
		</div>
		<van-row class="mining-sy">
		  <van-col span="10">
			  <div class="mining-sy-title">预计收益</div>
			  <div class="mining-sy-amount">75</div>
		  </van-col>
		  <van-col span="10">
			  <div class="mining-sy-title">累计收益</div>
			  <div class="mining-sy-amount">3825</div>
		  </van-col>
		  <van-col span="4">
			  <div class="mining-sy-title">托管订单</div>
			  <div class="mining-sy-amount">1</div>
		  </van-col>
		</van-row> -->
		<div class="mining-box">
			<div class="mining-item" v-for="(item,index) in dataList" :key="index" @click="placeOrder(item)">
				<!-- <div class="mining-item-header">
					<div class="mining-item-top">30天预期收益22.5%</div>
				</div>
				<div class="mining-item-header-seize"></div> -->
				<div class="mining-item-list">
					<div class="mining-item-list-left">
						<div class="mining-item-list-left-cover">
							<img :src="item.defi_img" />
						</div>
						<div>
							<div class="mining-item-list-left-title">{{item.defi_name}}</div>
							<div class="mining-item-list-left-text">{{ $t('assets.income') }} {{item.defi_rate}}%</div>
							<div class="mining-item-list-left-text">{{ $t('assets.money') }} {{item.defi_amount}}</div>
							<div class="mining-item-list-left-text">{{ $t('assets.cycle') }} {{item.defi_day}}{{ $t('assets.day') }}</div>
						</div>
					</div>
					<div class="mining-item-list-right">
						{{ $t('assets.ljty') }}
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data(){
			return {
				dataList:[]
			}
		},
		created(){
			this.getList()
		},
		methods: {
			async getList(){
				const { data } = await this.$http.get('/home/trade/defilist')
				if (data) {
				  if (data.code === 200) {
				    this.dataList = data.data
				  }
				}
			},
			/* 购买下单 */
			async placeOrder(item){
				this.$dialog.confirm({
				  title: this.$t('sell.tips'),
				  message: this.$t('assets.gmxd'),
				  confirmButtonText:this.$t('common.confirm'),
				  cancelButtonText:this.$t('common.cancel'),
				}).then(() => {
				    this.submitOrder(item)
				}).catch(() => {
				    // on cancel
				});
			},
			async submitOrder(item){
				const { data } = await this.$http.post('/home/trade/buydefi',{id:item.id})
				if (data) {
				  if (data.code === 200) {
					  this.getList()
					  this.$toast.success(this.$t('下单成功'))
				  }else{
            this.$toast.fail(this.$t('Defi余额不足'))
          }
				}
			}
		}
	}
</script>

<style lang="less">
	html{
		background-color: #f7f7f7;
	}
	.mining-nav{
		height: 3rem;
	}
	.mining-tg{
		padding: 0 1rem;
		margin-top: 1rem;
		text-align: left;
		.mining-tg-title{
			font-size: 0.9rem;
			color: #666;
		}
		.mining-tg-amount{
			font-size: 2rem;
			color: #000;
			font-weight: bold;
		}
	}
	.mining-sy{
		margin: 1rem 1rem 0 1rem;
		text-align: left;
		.mining-sy-title{
			font-size: 0.75rem;
			color: #666;
		}
		.mining-sy-amount{
			margin-top: 0.7rem;
			font-size: 1rem;
			color: #000;
			font-weight: bold;
		}
	}
	.mining-item{
		margin: 1rem 1rem 0 1rem;
		border-radius: 0.6rem;
		padding: 1rem;
		background-color: #fff;
		position: relative;
		.mining-item-header{
			position: absolute;
			top: 0;
			display: flex;
		}
		.mining-item-header-seize{
			height: 1.5rem;
		}
		.mining-item-top{
			padding: 0.4rem;
			background-color: #27CCAE;
			color: #fff;
			font-size: 0.7rem;
			border-radius: 0.1rem;
		}
		.mining-item-list{
			display: flex;
			align-items: center;
			justify-content: space-between;
			.mining-item-list-left{
				display: flex;
				align-items: center;
				text-align: left;
				.mining-item-list-left-cover{
					width: 3rem;
					height: 3rem;
					margin-right: 1rem;
					img{
						width: 100%;
						height: 100%;
					}
				}
				.mining-item-list-left-title{
					font-size: 1rem;
					color: #000;
					width: 12rem;
					display:  -webkit-box;
					word-break: break-all;
					-webkit-box-orient: vertical;
					-webkit-line-clamp:2;
					overflow: hidden;
					text-overflow:ellipsis;
				}
				.mining-item-list-left-text{
					margin-top: 0.7rem;
					font-size: 0.7rem;
					color: #000;
				}
			}
			.mining-item-list-right{
				width: 3.7rem;
				padding: 0.3rem 0.5rem;
				color: #fff;
				font-size: 0.75rem;
				border-radius: 0.2rem;
				background-color: #0055ff;
			}
		}
	}
</style>
